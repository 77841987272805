function getDictionaryDateFromString(string)
{
	var tmp = string.split(' ');
	var datestring = tmp[0].split('-');
	var timestring = tmp[1].split(':');

	return {
		'year': datestring[0].toInt(),
		'month': datestring[1].toInt(),
		'day': datestring[2].toInt(),
		'hours': timestring[0].toInt(),
		'minutes': timestring[1].toInt(),
		'seconds': timestring[2].toInt(),
		'milliseconds': 0
	};
}

function getDateFromString(string)
{
	var tmp = getDictionaryDateFromString(string);

	return new Date(tmp.year, tmp.month, tmp.day, tmp.hours, tmp.minutes, tmp.seconds, tmp.milliseconds);
}

function exit_fullscreen()
{
	if (document.cancelFullScreen)
		document.cancelFullScreen();
	else if (document.mozCancelFullScreen)
		document.mozCancelFullScreen();
	else if (document.webkitCancelFullScreen)
		document.webkitCancelFullScreen();

	window.location.href = document.getElementById('FullscreenIFrame').contentWindow.location.href;
}

function browserVerification()
{
	if (Browser.name != 'ie')
	{
		return;
	}

	var AllowedControllers   = ['dashboard', 'clients', 'prospects'];
	var Controller           = Auth.Request.controller;
	var notification_time    = 1000 * 60 * 60;
	var notified             = Date.now() - +Cookie.read('wrong_browser_notified') <= notification_time;
	var NotificationTemplate = [
		'<div id="IncrementaNotification" class="alert alert-info alert-dismissible fade in" role="alert">',
		'<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span>',
		'</button><p class="text-center"><span class="glyphicon glyphicon-info-sign pull-left"></span> &nbsp; ',
		'{notification_message}</p><p class="text-center">',
		'<button type="button" class="btn btn-info" data-dismiss="alert" aria-label="Close">',
		'{close_btn_label}</button> <a href="{option_btn_link}" class="btn btn-default" target="_blank" ',
		'style="text-decoration:none;">{option_btn_label}</a></p></div>'
	].join('');

	if (AllowedControllers.indexOf(Controller) == -1 || notified)
	{
		return;
	}

	var BrowserNotification = jQuery(NotificationTemplate.substitute({
		notification_message: Locale.get('BrowserNotification.message').substitute({
			browser_detected: 'Internet Explorer',
			browser_recommended: [
				'<a href="https://www.google.com/chrome/browser/desktop/index.html" ',
				'target="_blank" style="text-decoration:none;">Google Chrome</a>'
			].join('')
		}),
		close_btn_label: Locale.get('BrowserNotification.remindme_label'),
		option_btn_label: Locale.get('BrowserNotification.download_label').substitute({
			download_subject: 'Google Chrome'
		}),
		option_btn_link: 'https://www.google.com/chrome/browser/desktop/index.html'
	}))[0];

	jQuery(BrowserNotification).prependTo(jQuery('.app.container'));
	jQuery(BrowserNotification).on('close.bs.alert', function(){
		Cookie.write('wrong_browser_notified', Date.now(), {
			duration: false,
			path: Controller
		});
	}).alert();
}

function attachCleaveFunctionality(element)
{
	var cleave_type = element.get('data-cleave');
	var options     = {};

	if (cleave_type === 'currency')
	{
		var force_decimals = element.get('data-cleave-force-decimals') === 'true' || false;

		options = {
			numeral: true,
			numeralThousandsGroupStyle: 'thousand',
			numeralDecimalScale: force_decimals ? 8 : COMPANY_DECIMALS
		};
	}
	else if (cleave_type === 'credit_card')
	{
		options = {
			creditCard: true
		};
	}
	else if (cleave_type === 'phone')
	{
		options = {
			phone: true,
			phoneRegionCode: (element.get('cleave-country-code') || 'MX')
		};
	}
	else if (cleave_type === 'units')
	{
		options = {
			numeral: true,
			numeralThousandsGroupStyle: 'thousand'
		};
	}

	return new Cleave(element, options);
}

function transformDynamicSelect(select)
{
	var NotAllowedControllers = [
		'dashboard',
		'reporting',
		'price_quotes',
		'status_actions',
		'clients',
		'prospects',
		'product_lists',
		'projects'
	];

	var Controller = Auth.Request.controller;

	if (NotAllowedControllers.indexOf(Controller) > -1)
	{
		return;
	}
	if (select.get('hidden') || select.get('type') == 'hidden' || select.hasClass('hide'))
	{
		return;
	}
	if (select.hasClass('dynamic-search'))
	{
		return;
	}
	if (Controller == 'worklists')
	{
		if (select.hasClass('country_id') || select.hasClass('state_id') || select.hasClass('city_id')){
			return;
		}
		else if (select.match('#ClientCountryId') || select.match('#ClientStateId') || select.match('#ClientCityId')){
			return;
		}
	}

	new DynamicSearch({
		target: select,
		multiple: !!select.getProperty('multiple'),
		search: {
			enable: select.getElements('option').length > 10,
			min_chars: 2
		},
		style: 'width: 100%'
	});
}

function isEmailValid(string)
{
	var isValid = true;

	string.split(',').filter(Boolean).forEach(function(email){
		if (!email.match(/^([\w-\.]+)@(([A-Za-z0-9\. -]+)\.([a-zA-Z]{2,64}))$/g))
		{
			isValid = false;

			return;
		}
	});

	return isValid;
}

function bytesTranslate(size_in_bytes)
{
	var bytes = +size_in_bytes;

	if (bytes >= 1073741824)
	{
		bytes = (bytes / 1073741824).toFixed(2) + ' GB';
	}
	else if (bytes >= 1048576)
	{
		bytes = (bytes / 1048576).toFixed(2) + ' MB';
	}
	else if (bytes >= 1024)
	{
		bytes = (bytes / 1024).toFixed(2) + ' KB';
	}
	else if (bytes > 1)
	{
		bytes = bytes + ' bytes';
	}
	else if (bytes == 1)
	{
		bytes = bytes + ' byte';
	}
	else
	{
		bytes = '0 byte';
	}

	return bytes;
}

function googleMapsInit(callback)
{
	if (window.google)
	{
		callback();
	}
	else
	{
		new Element('script', {
			type: 'text/javascript',
			src: [
				'//maps.googleapis.com/maps/api/js?key=', GOOGLE_MAPS_API_KEY,
				'&sensor=true',
				'&callback=googleMapsReady',
			].join('')
		}).inject(document.body);

		window.GOOGLE_MAPS_CALLBACK = callback;
	}
}

function googleMapsReady()
{
	if (window.GOOGLE_MAPS_CALLBACK)
	{
		window.GOOGLE_MAPS_CALLBACK();
		delete window.GOOGLE_MAPS_CALLBACK;
	}
}
