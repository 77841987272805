window.sentryOnLoad = function(){
	try
	{
		if (SENTRY_DATA.dsn)
		{
			Sentry.init({
				dsn: SENTRY_DATA.dsn,
				release: SENTRY_DATA.release,
				tracesSampleRate: 0.0,
				replaysSessionSampleRate: 0.0,
				replaysOnErrorSampleRate: 0.0,
				integrations: [
					Sentry.browserTracingIntegration(),
					Sentry.replayIntegration()
				],
			});

			if (SENTRY_DATA.user)
			{
				Sentry.configureScope(function(scope) {
					scope.setUser(SENTRY_DATA.user);
				});
			}

			var NotAllowedControllers = [
				'notifications'
			];

			var Controller = Auth.Request.controller;

			if (NotAllowedControllers.indexOf(Controller) > -1)
			{
				return;
			}

			if (typeof SentryLastEventId != 'undefined')
			{
				new Request({
					url: '/sentry/user_feedback_form',
					onSuccess: function(sentry_form){
						var user_feedback_modal = new BootstrapModal({
							title: '',
							primaryButton: {
								html: '<span class="glyphicon glyphicon-notes"></span> ' + Locale.get('Default.send'),
							},
							secondaryButton: {
								class: 'btn btn-default',
								'data-dismiss': 'modal',
								html: Locale.get('Default.close')
							},
							previousButton: null,
							onPrimary: function(event)
							{
								event.stop();

								var FeedbackForm = $('UserFeedbackForm');

								new Request({
									url: '/sentry/user_feedback/' + SentryLastEventId,
									data: FeedbackForm.toQueryString(),
									onRequest: function(){
										user_feedback_modal.body.getElements('input,textarea').each(function(input){
											input.set('disabled', 'disabled');
											input.addClass('disabled');
										});
									},
									onComplete: function(res){
										var response = JSON.decode(res);

										user_feedback_modal.body.set('html', [
											'<h1 class="text-center text-muted">',
											Locale.get('Sentry.thanks'),
											'</h1>'
										].join(''));

										setTimeout(function(){
											user_feedback_modal.dispose();
										}, 3000)
									}
								}).send();
							},
							body: sentry_form
						});

						user_feedback_modal.show();
					}
				}).send();
			}
		}
	}
	catch (e)
	{
		console.warn('Sentry failed to load:', e);
	}
};
