var Loader = new Class({
	timeout:      5000,
	sending:      false,
	element:      null,
	inputs:       null,
	timer:        null,
	hiddenSubmit: null,

	initialize: function(form)
	{
		if (form)
		{
			this.element = form.getElement('.loader');
			this.inputs  = form.getElements('input[type=submit], button[type=submit]');

			this.inputs.addEvent('click', this.onClickButtonSubmit.bind(this));

			form.addEvent('submit', this.onSubmit.bind(this));
			form.addEvent('cancel', this.stopAndReset.bind(this));
		}
	},
	onSubmit: function(event)
	{
		if (this.sending)
		{
			event.stop();
		}
		else
		{
			this.sending = true;
			this.inputs.set('disabled', true);

			if (this.element)
			{
				this.element.removeClass('hide');
			}
			this.timer = setTimeout(this.stopAndReset.bind(this), this.timeout);
		}
	},
	stopAndReset: function()
	{
		this.sending = false;
		this.inputs.set('disabled', false);
		if (this.element)
		{
			this.element.addClass('hide');
		}
		clearTimeout(this.timer);
	},
	onClickButtonSubmit: function(event)
	{
		var Submit = $(event.target);

		if (this.hiddenSubmit === null)
		{
			this.hiddenSubmit = new Element('input', {type: 'hidden'}).inject(Submit.getParent('form'), 'bottom');
		}
		this.hiddenSubmit.set('name', Submit.get('name')).set('value', Submit.get('value'));
	}
});
