window.addEvent('domready', function(){
	var CleaveElements         = $$('[data-cleave]');
	var CompanySettings        = $$('.form-tabs');
	var NavTabsCompanySettings = $('NavTabsCompanySettings');
	var SelectElements         = $$('select');

	browserVerification();
	CleaveElements.each(attachCleaveFunctionality);
	SelectElements.each(transformDynamicSelect);

	if (CompanySettings.length > 0 && NavTabsCompanySettings)
	{
		var tav = document.URL.split('#');
		if (tav[1])
		{
			jQuery('#NavTabsCompanySettings a[href="#' + tav[1] + '"]').tab('show');
			CompanySettings.set('action', tav[0] + '#' + tav[1]);
		}
		else
		{
			jQuery('#NavTabsCompanySettings a:first').tab('show');
		}
		NavTabsCompanySettings.getElements('li a').addEvent('click', function(){
			CompanySettings.set('action', tav[0] + this.get('href'));
		});
	}

	var CheckboxesSwitch = $$('.input-switch');
	if (CheckboxesSwitch.length > 0)
	{
		CheckboxesSwitch.each(function(element, i){
			if (element)
			{
				new Element('label', {'for': element.get('id')}).inject(element, 'after');
				element.addClass('input-switch-enable');
			}
		});
	}

	var Fullscreen = $('Fullscreen');
	if (Fullscreen)
	{
		var fullscreenWrapper = $('fullscreenWrapper');
		var is_fullscreen = (window.innerHeight == screen.height);
		var is_fullscreen_mode = function()
		{
			if (window.innerHeight != screen.height)
			{
				exit_fullscreen();
			}
		}
		var is_fullscreen_mode_timer = null;

		Fullscreen.addEvent('click', function(event){
			event.stop();
			if (is_fullscreen)
			{
				parent.exit_fullscreen();
			}
			else
			{
				if (fullscreenWrapper.requestFullScreen)
				{
					fullscreenWrapper.requestFullScreen();
					is_fullscreen = true;
				}
				else if (fullscreenWrapper.mozRequestFullScreen)
				{
					fullscreenWrapper.mozRequestFullScreen();
					is_fullscreen = true;
				}
				else if (fullscreenWrapper.webkitRequestFullScreen)
				{
					fullscreenWrapper.webkitRequestFullScreen();
					is_fullscreen = true;
				}
				if (is_fullscreen)
				{
					fullscreenWrapper.addClass('enabled');
					fullscreenWrapper.set('html', '<iframe id="FullscreenIFrame" src="' + document.URL + '" style="border: 0; width: 100%; height: 100%">Your browser doesn\'t support fullscreen.</iframe>');
					(function() {
						setInterval(is_fullscreen_mode, 500);
					}).delay(1500);
				}
			}
		});
	}

	var GlobalSearchInput = $('GlobalSearchInput');
	var GlobalSearchForm  = $('GlobalSearchForm');
	if (GlobalSearchInput && GlobalSearchForm)
	{
		GlobalSearchInput.addEvents({
			'focus': function(event)
			{
				GlobalSearchForm.addClass('focus');
			},
			'blur': function(event)
			{
				if (this.get('value') === '')
					GlobalSearchForm.removeClass('focus');
			}
		});
	}

	var today = new Date();

	$$('.action_date_picker, .input-datetime').each(function(input){
		input.setProperty('autocomplete', 'off');

		new Picker.Date(input, {
			startDay: START_DAY,
			pickerClass: 'datepicker_incrementa',
			format: '%Y-%m-%d %H:%M:00',
			timePicker: true,
			useFadeInOut: false,
			draggable: false,
			minDate: input.hasClass('action_date_picker') ? today : null,
			onSelect: function(selected_date){
				var today = new Date();

				if (selected_date.toDateString() == today.toDateString())
				{
					var s_hours   = selected_date.getHours();
					var s_minutes = selected_date.getMinutes();
					var t_hours   = today.getHours();
					var t_minutes = today.getMinutes();

					if (s_hours <= t_hours && s_minutes <= t_minutes)
					{
						alert(Locale.get('Date.retroactiveTime'));
						this.input.set('value', null);
					}
				}
			}
		});
	});

	$$('.input-date').each(function(input){
		input.setProperty('autocomplete', 'off');

		new Picker.Date(input, {
			startDay: START_DAY,
			pickerClass: 'datepicker_incrementa',
			format: '%Y-%m-%d',
			timePicker: false,
			useFadeInOut: false,
			draggable: false
		});
	});

	$$('.input-time').each(function(input){
		input.setProperty('autocomplete', 'off');

		new Picker.Date(input, {
			startDay: START_DAY,
			pickOnly: 'time',
			pickerClass: 'datepicker_incrementa',
			useFadeInOut: false,
			draggable: false,
			format: '%H:%M:%S',
			onSelect: function(selected_date){
				if (!RETROACTIVE_SCHEDULING)
					return;

				var today = new Date();

				if (selected_date.toDateString() == today.toDateString())
				{
					var s_hours   = selected_date.getHours();
					var s_minutes = selected_date.getMinutes();
					var t_hours   = today.getHours();
					var t_minutes = today.getMinutes();

					if (s_hours <= t_hours && s_minutes <= t_minutes)
					{
						alert(Locale.get('Date.retroactiveTime'));
						this.input.set('value', null);
					}
				}
			}
		});
	});

	$$('.input-date-range').each(function(input){
		input.setProperty('autocomplete', 'off');

		var button = input.getParent('.input-date-range-button');
		var label = button.getElement('.input-date-range-label');
		var date_range = input.get('data-init-dates') ? input.get('data-init-dates') : input.get('value');

		var picker = new Picker.Date.Range(input, {
			startDay: START_DAY,
			pickerClass: 'datepicker_incrementa',
			toggle: [button],
			format: '%Y-%m-%d',
			columns: 2,
			useFadeInOut: false,
			positionOffset: input.hasClass('input-date-range-right') ? {x: -200, y: 0} : {x: 0, y: 0},
			draggable: true,
			getStartEndDate: function(input){
				return date_range.split(' - ').map(function(date){
					var parsed = Date.parse(date + ' 00:00:00').setUserOffset();
					return Date.isValid(parsed) ? parsed : null;
				}).clean();
			},
			onSelect: function(){
				label.set('text', Array.map(arguments, function(date){
					return date.format(Locale.get('Date.naturalDate'));
				}).join(' — '));
			}
		});
		button.addEvent('click', function(event){
			event.stop();
			picker.show();
		});
		var dates = input.get('value').match(/^(\d{4}-\d{1,2}-\d{1,2})\ \-\ (\d{4}-\d{1,2}-\d{1,2})$/);
		if (dates)
		{
			var date1 = Date.parse(dates[1] + ' 00:00:00').format(Locale.get('Date.naturalDate'));
			var date2 = Date.parse(dates[2] + ' 23:59:59').format(Locale.get('Date.naturalDate'));

			label.set('text', date1 + ' — ' + date2);
		}
	});

	$$('.form-group.error').each(function(e){
		var input = e.getElement('.form-control');
		var alert_danger = e.getElement('.alert.alert-danger');

		if (input && alert_danger)
		{
			input.addEvents({
				focus: function() {
					e.addClass('focused');
				},
				blur: function() {
					e.removeClass('focused');
				}
			})
		}
	});

	var modal_loder = new Element('p', {class: 'text-center'});
	new Element('span', {class: 'loader loader-default'}).inject(modal_loder);

	var ModalNewLeadNewClient = $('ModalNewLeadNewClient');
	var ModalNewLeadNewProspectus = $('ModalNewLeadNewProspectus');
	var ModalNewLead = $('ModalNewLead');
	var load_model_content = function(controller, data)
	{
		if (data === null && ModalNewLead.getElement('.modal-body'))
			modal_loder.inject(ModalNewLead.getElement('.modal-body').set('html', ''));

		jQuery('#ModalNewLead').modal('show');
		new Request({
			method: 'post',
			data: data,
			url: '/' + controller + '/quick_add/',
			onFailure: function(error)
			{
				window.location.reload();
			},
			onSuccess: function(response)
			{
				ModalNewLead.getElement('.modal-content').set('html', response);
				var Selects = ModalNewLead.getElements('select');
				if (Selects)
				{
					Selects.each(function(select){
						var ds_select = new DynamicSearch({
							target: select,
							style: 'width: 100%',
							label: {
								empty: select.getProperty('placeholder') || ' — ',
								plural: select.getProperty('data-plural') || Locale.get('Label.elements'),
								singular: select.getProperty('data-singular') || Locale.get('Label.element'),
							},
							multiple: !!select.getProperty('multiple'),
							search: {
								enable: select.getElements('option').length > 10,
								min_chars: 1
							}
						});

						if (select.get('id') == 'GlobalStatusActionId' )
						{
							select.addEvent('updated', function(event){
								ds_select.setOptions({
									search: {
										enable: select.getElements('option').length > 10,
										min_chars: 1
									}
								});

								ds_select.rebuild();
							})
						}
						var dynamic_selects = ['ClientStateId', 'ProspectusStateId', 'ClientCityId', 'ProspectusId'];
						var dynamic_select = select.get('id');
						if (dynamic_selects.indexOf(dynamic_select) > -1)
						{
							select.addEvent('updated', function(event){
								ds_select.setOptions({
									search: {
										enable: select.getElements('option').length > 10,
										min_chars: 1
									}
								});

								ds_select.rebuild();
							})
						}
					});
				}
				initialize_project_form();
				mark_required_fields();
				send_new_lead(controller);
			}
		}).send();
	}

	if (ModalNewLeadNewClient)
	{
		ModalNewLeadNewClient.addEvent('click', function(event){
			event.stop();
			load_model_content('clients', null);
		});
	}

	if (ModalNewLeadNewProspectus)
	{
		ModalNewLeadNewProspectus.addEvent('click', function(event){
			event.stop();
			load_model_content('prospects', null);
		});
	}

	var initialize_project_form = function()
	{
		var ActionActionDateAjax  = $('ActionActionDateAjax');
		var ProjectStatusActionId = $('GlobalStatusActionId');
		if (ActionActionDateAjax)
		{
			new Picker.Date(ActionActionDateAjax, {
				startDay: START_DAY,
				pickerClass: 'datepicker_incrementa',
				format: 'db',
				timePicker: true,
				useFadeInOut: false,
				draggable: false,
				minDate: today
			});
		}
		var ShowQuickAddProjectAjax = $('ShowQuickAddProjectAjax');
		if (ShowQuickAddProjectAjax)
		{
			var QuickAddAjax = $('QuickAddAjax');
			ShowQuickAddProjectAjax.addEvent('click', function(event){
				event.stop();
				var ProjectTypeSelect = QuickAddAjax.getElement('#ProjectProjectTypeId');
				QuickAddAjax.getElements('.quick-add-project-ajax').toggleClass('hide');

				if (ProjectTypeSelect)
				{
					ProjectTypeSelect.addEvent('change', function(){
						var project_type_selected = +this.value;

						QuickAddAjax.getElements('[project-type]').each(function(custom_field){
							var custom_field_project_type_id = +custom_field.getProperty('project-type');
							custom_field.toggleClass('hidden', project_type_selected !== custom_field_project_type_id);
						});
					});
				}
				(function(){
					jQuery('#ModalNewLead').modal('handleUpdate');
				}).delay(100);
			});
		}
		if (ProjectStatusActionId)
		{
			var ProjectTypeSelect = $('QuickAddAjax').getElement('#ProjectProjectTypeId');

			var request_status_actions = new Request.JSON({
				onSuccess: function(response)
				{
					update_status_actions(response.response.status_actions);
				}
			});

			var update_status_actions = function(status_actions)
			{
				var options = status_actions.map(function(status_action){
					return '<option value="' + status_action.value + '">' + status_action.name + '</option>';
				});

				ProjectStatusActionId.set('html', options.join(''));
				ProjectStatusActionId.fireEvent('updated');
			}

			if (ProjectTypeSelect)
			{
				ProjectTypeSelect.addEvent('change', function(){
					request_status_actions.send({
						url: '/projects/status_actions/project_type_id:' + this.get('value') + '.json'
					});
				});

				ProjectTypeSelect.fireEvent('change');
			}
			else
			{
				request_status_actions.send({url: '/projects/status_actions/project_type_id:0.json'});
			}
		}
		var FormNewLeadAjax = $('FormNewLeadAjax');
		if (FormNewLeadAjax)
		{
			var select_countries = FormNewLeadAjax.getElement('select.country_id');
			var select_states = FormNewLeadAjax.getElement('select.state_id');
			var select_cities = FormNewLeadAjax.getElement('select.city_id');

			if (select_countries && select_states)
			{
				select_countries.addEvent('change', function(){
					new Request({
						method: 'post',
						url: '/countries/get_states',
						onRequest: function()
						{
							select_states.set('disabled', true);
						},
						onSuccess: function(response)
						{
							if (response != 'NO STATES')
							{
								select_states.set('html', response);
								select_states.set('disabled', false);
							}
							else
							{
								select_states.set('html', '<option value="">' + Locale.get('Default.noStates') + '</option>');
								select_states.set('disabled', true);
							}

							select_states.fireEvent('updated');
						}
					}).send('data[country_id]=' + select_countries.get('value'));
				});
			}
			if (select_states && select_cities)
			{
				select_states.addEvent('change', function(){
					new Request({
						method: 'post',
						url: '/states/get_cities',
						onRequest: function()
						{
							select_cities.set('disabled', true);
						},
						onSuccess: function(response)
						{
							if (response != 'NO CITIES'){
								select_cities.set('html', response);
								select_cities.set('disabled', false);
							}
							else {
								select_cities.set('html', '<option value="">' + Locale.get('Default.noCities') + '</option>');
								select_cities.set('disabled', true);
							}

							select_cities.fireEvent('updated');
						}
					}).send('data[state_id]=' + select_states.get('value'));
				});
			}
			var SendNotification = FormNewLeadAjax.getElement('.send-notification');
			if (SendNotification)
			{
				var actions_to_sync = [];

				if (Auth.UserSettings.google_sync_calls === '1')
					actions_to_sync.push(1);

				if (Auth.UserSettings.google_sync_meetings === '1')
					actions_to_sync.push(2);

				if (Auth.UserSettings.google_sync_emails === '1')
					actions_to_sync.push(3);

				if (Auth.UserSettings.google_sync_activities === '1')
					actions_to_sync.push(4);

				actions_to_sync = actions_to_sync.join(',');

				var ClientEmail = FormNewLeadAjax.getElement('#ClientEmail');
				var ActionTypeId = FormNewLeadAjax.getElement('#ActionActionTypeId');

				var should_show_send_notification = function()
				{
					if (actions_to_sync.search(ActionTypeId.get('value')) > -1 && ClientEmail.get('value').test(/\S+@\S+\.\S+/))
					{
						SendNotification.removeClass('hide');
						SendNotification.getElement('.send-notification-email').set('text', ClientEmail.get('value'));
					}
					else
					{
						SendNotification.addClass('hide');
						SendNotification.getElement('.send-notification-email').set('text', '');
						SendNotification.getElement('input').set('checked', false);
					}
				}
				ClientEmail.addEvent('change', should_show_send_notification);
				ActionTypeId.addEvent('change', should_show_send_notification);

				should_show_send_notification();
			}
		}

		var toggle_group_assigned = function(event)
		{
			event.stop();
			this.setProperty('class', this.hasClass('label-default') ? 'label label-success' : 'label label-default');

			var groups = $$('.quick-add-groups .label-success');
			var group_ids = [];
			for (var n = 0; n < groups.length; n++)
			{
				group_ids.push(groups[n].get('data-group_id'));
			}
			this.getParent('.quick-add-groups').getElement('input').set('value', group_ids.join(','));
		}

		var toggle_group_autoselect = function()
		{
			var groups = $$('.quick-add-groups .label').get('data-group_id').map((group) => parseInt(group));
			var selected_groups = [];
			var autoselected_groups = Auth.User.groups;

			for (var i = 0; i < autoselected_groups.length; i++)
			{
				if (groups.includes(autoselected_groups[i]))
				{
					selected_groups.push(autoselected_groups[i]);
				}
			}

			$$('.quick-add-groups .label').each((group_label) => {
				var group_selected  = parseInt(group_label.get('data-group_id'));

				if (selected_groups.includes(group_selected))
				{
					group_label.addClass('label-success');
				}
			});

			$$('.quick-add-groups').getElement('input').set('value', selected_groups.join(','));
		}

		if (Auth.User.user_type_id === 1 && parseInt(Auth.CompanySettings.disable_reassignment))
		{
			$$('.quick-add-groups .label').removeProperty('style');
		}
		else
		{
			$$('.quick-add-groups .label').addEvent('click', toggle_group_assigned);
		}

		if (Auth.CompanySettings.groups_autoselect)
		{
			toggle_group_autoselect();
		}
	}

	var ShowQuickAddProject = $('ShowQuickAddProject');
	if (ShowQuickAddProject)
	{
		var QuickAdd = $('QuickAdd');
		ShowQuickAddProject.addEvent('click', function(event){
			event.stop();
			QuickAdd.getElements('.quick-add-project').toggleClass('hide');
		});
	}

	initialize_project_form();

	var send_new_lead = function(controller)
	{
		var ClientCompanyName = $('ClientCompanyName');
		if (ClientCompanyName)
		{
			ClientCompanyName.focus();
		}

		var FormNewLeadAjax = $('FormNewLeadAjax');
		if (FormNewLeadAjax)
		{
			var ActionActionDateAjax = $('ActionActionDateAjax');
			var ClientUserAssigned   = FormNewLeadAjax.getElement('#ClientUserAssignedId');
			var ActionActionTypeId   = FormNewLeadAjax.getElement('#ActionActionTypeId');

			if (SIMULTANEOUS_ACT)
			{
				var check_simultaneous_activities = function()
				{
					var datetime = ActionActionDateAjax.get('value');
					var user_id = ClientUserAssigned.get('value') == '' ? Auth.User.id : ClientUserAssigned.get('value');
					if (datetime === '')
					{
						return;
					}

					var OverlapActivities = FormNewLeadAjax.getElement('#OverlapActivities');

					if (OverlapActivities)
					{
						OverlapActivities.destroy();
					}

					new Request.JSON({
						url: '/actions/simultaneous.json',
						data: {user_id: user_id, datetime: datetime},
						onSuccess: function(response)
						{
							if (response.response.error)
							{
								location.reload();
							}
							if (response.response.simultaneousActivities)
							{
								var count = response.response.count;

								var activities_list = new Element('ul', {class: 'list-group'});
								for (var activity in count)
								{
									var activities = +count[activity];

									if (activities)
									{
										var activity_item = new Element('li', {
											class: 'list-group-item text-' + activity,
											text: Locale.get('System.' + activity)
										});
										var badge = new Element('span', {class: 'badge text-danger', 'text': activities});

										badge.inject(activity_item);
										activity_item.inject(activities_list);
									}
								}

								FormNewLeadAjax.getElement('.modal-body').adopt(new Element('div', {
									id: 'OverlapActivities',
									class: 'text-danger left',
									html: [
										'<label class="label label-danger">',
										Locale.get('System.simultaneousActivities'),
										'</label></br></br>'
									].join(''),
									style: 'width: 60%; margin-right: auto;'
								}).adopt(activities_list));
							}
						}
					}).send();
				}

				if (ActionActionDateAjax)
				{
					ActionActionDateAjax.addEvent('change', check_simultaneous_activities);
				}

				if (ClientUserAssigned)
				{
					ClientUserAssigned.addEvent('change', check_simultaneous_activities);
				}

				if (ActionActionTypeId)
				{
					ActionActionTypeId.addEvent('change', check_simultaneous_activities);
				}
			}

			if (SCHEDULE_RESTRICTION)
			{
				var is_action_date_allowed = function()
				{
					var datetime = ActionActionDateAjax.get('value');

					var InvalidTime = FormNewLeadAjax.getElement('#InvalidTime');

					if (InvalidTime)
					{
						InvalidTime.destroy();
					}

					new Request.JSON({
						url: '/actions/in_allowed_timetable.json',
						data: {datetime: datetime},
						onSuccess: function(response)
						{
							if (response.response.error)
							{
								location.reload();
							}
							if (response.response.invalid_action_date)
							{
								FormNewLeadAjax.getElement('.modal-body').adopt(new Element('p', {
									id: 'InvalidTime',
									class: 'text-danger right',
									html: Locale.get('System.timetableRestriction')
								}));

								FormNewLeadAjax.getElement('input[type=submit]').setProperty('disabled', 'disabled');
							}
							else
							{
								FormNewLeadAjax.getElement('input[type=submit]').removeProperty('disabled');
							}
						}
					}).send();
				}

				if (ActionActionDateAjax)
				{
					ActionActionDateAjax.addEvent('change', is_action_date_allowed);
				}
			}

			FormNewLeadAjax.addEvent('submit', function(event){
				event.stop();
				var QuickAddAjax = $('QuickAddAjax');
				var project_fields = QuickAddAjax.getElements('.form-group:not(.hidden) .special-field-project, .form-group:not(.hidden) .asterisk-input-required');
				var input;
				var input_container;
				var valid_form = true;
				if (!QuickAddAjax.getElement('.quick-add-project-ajax').hasClass('hide'))
				{
					for (var x = 0; x < project_fields.length; x++)
					{
						input = project_fields[x];
						input_container = input.getParent('.form-group');

						if (input.get('value'))
						{
							input_container.removeClass('has-error');
						}
						else
						{
							valid_form = false;
							input_container.addClass('has-error');
						}
					}
				}

				if (valid_form)
				{
					load_model_content(controller, FormNewLeadAjax.toQueryString());
				}
			});
			new Loader(FormNewLeadAjax);
		}
	}

	var ProspectusUserAssignedId = $$('.hidden-input-user-assigned-id');
	var UsserAssignmentSelect = $$('.usser-assignment-select');

	if (ProspectusUserAssignedId && UsserAssignmentSelect)
	{
		var AddClientUserAssignment = $$('.add-client-user-assignment');
		var SpanAssignmentDisplay = $('span-assignment-display');

		AddClientUserAssignment.addEvent('click', function(event){
			event.stop();
			$$('.mark-user-assignment-selected').addClass('hidden');
			$$('.dropdown-menu-assignees').setStyle('width', '180px')
			AddClientUserAssignment.removeClass('strong');
			this.addClass('strong').set(
				'html',
				this.get('text') +
				'<span class="mark-user-assignment-selected glyphicon glyphicon-check pull-right"></span>'
			);
			ProspectusUserAssignedId.set('value', this.get('user_id'));
			SpanAssignmentDisplay.set('html', this.get('text'));
			UsserAssignmentSelect.removeClass('open');
		});
	}

	$$('form').each(function(form){
		if (form.hasClass('ignore-loader') === false)
		{
			new Loader(form);
		}
	});
	mark_required_fields();

	$$('.special-file-input').each(function(input){
		new SpecialFileInput(input);
	});
});

jQuery(document).on('shown.bs.modal', function(event){
	setTimeout(function(){
		jQuery(event.target).find('select').each(function(i, element){
			transformDynamicSelect(element);
		});
	}, 200);
});

jQuery(document).ready(function($) {
	jQuery('.support-unavailable').popover({
		placement: 'top',
		title: 'Soporte México',
		content: [
			// '<div style="white-space: nowrap;">De lunes a viernes:</div>',
			// '<div style="white-space: nowrap;">9:00 AM - 6:00 PM</div>',
			'<div style="white-space: nowrap;">Operadores ocupados por el momento.</div>',
			'<div style="white-space: nowrap;">',
			'<a href="http://docs.incrementacrm.com/" target="_blank">Documentación</a>',
			'</div>',
		].join(''),
		html: true
	});

	var modal_clean_inputs = $('.modal-on-hide-clean-inputs')
	var input_type_exclude_clean = ['hidden', 'button', 'submit'];

	modal_clean_inputs.on('hidden.bs.modal', function(e) {
		modal_clean_inputs.find('form :input').each(function(index) {

			var input_type = $(this).prop('type')

			if (input_type_exclude_clean.indexOf(input_type) == -1)
			{
				$(this).val('');
				$(this).parent().parent().parent().removeClass('has-error');
				$(this).parent().parent().removeClass('has-error');
			}
		});
	});
});

/**
 * Changes the view mode for containers:
 *
 * - Compact static.
 * - Extended fluid.
 */
function change_container()
{
	var ChangeContainer = $('ChangeContainer');
	var containers = $$('.container, .container-fluid');
	var isFluid = containers[0].hasClass('container-fluid');
	var container_icon = '<i class="' + ('glyphicon glyphicon-' + (isFluid ? 'door-open' : 'door-close')) + '"></i>';

	containers.each(function(container){
		container.toggleClass('container', isFluid).toggleClass('container-fluid', !isFluid);
	});

	new Request.JSON({
		url: '/dashboard/change_containers/new_mode:' + (isFluid ? 'compact' : 'extended') + '.json',
		method: 'GET'
	}).send();

	ChangeContainer.set('html', [container_icon, isFluid ? 'Vista extendida' : 'Vista compacta'].join(' '));
}

/**
 * Marca los campos requeridos con un asterisco.
 *
 * NOTA: Esta función necesita una mejora para evitar que
 * cuando sea llamado por una actualización de Ajax, el
 * selector tenga que recorrer de nuevo inputs que ya había
 * procesaro.
 *
 * @author Iván Vazquez
 */
var mark_required_fields = function()
{
	var elements_required = [
		'input[required="required"]',
		'select[required="required"]',
		'textarea[required="required"]',
		'.asterisk-input-required',
		'input[name="data[Prospectus][first_name]"]',
		'input[name="data[Client][first_name]"]'
	];
	var form_rows = $$(elements_required.join()).getParent('.form-group, .control-group');
	form_rows.each(function(form_row){
		var label = null;

		if (form_row)
			label = form_row.getElement('.control-label');

		if (label && label.getElement('.asterisk-required-field') === null)
			label.set('html', '<span class="asterisk-required-field">*</span> ' + label.get('text'));
	});

	// asteriscos especiales en placholder
	var inputs_asterisks_placeholder = $$('.asterisk-placeholder');
	if (inputs_asterisks_placeholder.length > 0)
	{
		inputs_asterisks_placeholder.each(function(input){
			if (input)
			{
				input.set('placeholder', '* ' + input.get('placeholder').replace(/(\s|\*)(\s)/g, ''));
			}
		});
	}

	if ((Auth.Request.controller === 'clients' || Auth.Request.controller === 'prospects') && Auth.Request.action === 'view')
	{
		var ClientView = $('ClientView');
		if (ClientView)
		{
			var tracking_required_fields = ClientView.getElements('.tracking-required-fields');
			var asterisk_required_field = ClientView.getElements('.asterisk-required-field');
			asterisk_required_field.addClass('hide');
			tracking_required_fields.each(function(label){
				if (label !== null && label.getElement('.asterisk-required-field') === null)
					label.set('html', '<span class="asterisk-required-field">*</span>' + label.get('text'));
			});
		}
	}
}
