var BootstrapModal = new Class({
	Implements: Options,
	modal: null,
	options: {
		title: 'Title',
		body: '<p>' + Locale.get('Default.loading') + '...</p>',
		footer: null,
		size: null,
		destroyOnHide: true,
		primaryButton: {
			class: 'btn btn-primary',
			'data-dismiss': 'modal',
			html: Locale.get('Default.submit')
		},
		secondaryButton: {
			class: 'btn btn-default',
			'data-dismiss': 'modal',
			html: Locale.get('Default.close')
		},
		previousButton: null,
		onPrimary: null,
		onSecondary: null,
		onPrevious: null
	},
	primaryButton: null,
	secondaryButton: null,
	previousButton: null,
	listeners: [],

	initialize: function(options)
	{
		this.setOptions(options);
		this.build();
	},

	build: function()
	{
		var modal_size = this.options.size ? 'modal-' + this.options.size : '';

		this.modal = new Element('div', {
			class: 'modal fade',
			tabindex: '-1',
			role: 'dialog'
		}).set('html', [
			'<div class="modal-dialog ' + modal_size + '" role="document">',
				'<div class="modal-content">',
					'<div class="modal-header">',
						'<button type="button" class="close" data-dismiss="modal" aria-label="Close">',
							'<span aria-hidden="true">&times;</span>',
						'</button>',
						'<h4 class="modal-title">' + this.options.title + '</h4>',
					'</div>',
					'<div class="modal-body">' + this.options.body + '</div>',
					'<div class="modal-footer"></div>',
				'</div>',
			'</div>',
		].join(''));

		this.persistent_lock_handler = function(e){
			e.preventDefault();
			e.stopPropagation();

			return false;
		}

		if (this.options.destroyOnHide)
		{
			jQuery(this.modal).on('hidden.bs.modal', this.destroy.bind(this));
		}
		jQuery(this.modal).on('hidden.bs.modal', function(){
			$(document.body).removeClass('modal-open');
		}).on('shown.bs.modal', function(){
			$(document.body).addClass('modal-open');
		});

		this.header = this.modal.getElement('.modal-header');
		this.body   = this.modal.getElement('.modal-body');
		this.footer = this.modal.getElement('.modal-footer');

		if (!this.options.footer)
		{
			this.footer.set('html', this.options.footer);
		}
		if (this.options.primaryButton)
		{
			var primary_events = this.options.onPrimary ? {click: this.options.onPrimary} : {};

			this.primaryButton = new Element('button', this.options.primaryButton).addEvents(primary_events);
			this.footer.grab(this.primaryButton);
			this.addListener(this.primaryButton);
		}
		if (this.options.secondaryButton)
		{
			var secondary_events = this.options.onSecondary ? {click: this.options.onSecondary} : {};

			this.secondaryButton = new Element('button', this.options.secondaryButton).addEvents(secondary_events);
			this.footer.grab(this.secondaryButton);
			this.addListener(this.secondaryButton);
		}
		if (this.options.previousButton)
		{
			var previous_events = this.options.onPrevious ? {click: this.options.onPrevious} : {};

			this.previousButton = new Element('button', this.options.previousButton).addEvents(previous_events);
			this.footer.grab(this.previousButton);
			this.addListener(this.previousButton);
		}

		$(document.body).grab(this.modal);
	},

	setRows: function(rows, field_class, value_class)
	{
		var templates = {
			fields_and_values: [
				'<div class="row">',
					'<label class="' + (field_class || 'col-sm-4') + ' control-label">{field}</label>',
					'<div class="' + (value_class || 'col-sm-8') + '">',
						'<p class="form-control-static">{value}</p>',
					'</div>',
				'</div>',
			].join(''),
			br: '<br />',
			hr: '<hr />',
			title: '<div class="row"><div class="col-sm-12"><h4 class="text-center">{title}</h4></div></div>',
			switch: [
				'<div class="row">',
					'<div class="col-sm-2 col-sm-offset-5">',
						'<input type="hidden" name="data[is_global]" id="{switch}_" value="0">',
						'<input ',
							'id="{switch}" ',
							'type="checkbox" ',
							'name="data[is_global]" ',
							'class="form-control input-switch input-switch-enable" ',
							'value="1" />',
							'<label for="{switch}"></label>',
					'</div>',
				'</div>',
			].join(''),
			collapse: '<div id="{collapse}" class="{collapse} collapse">',
			collapse_end: '</div>'
		};

		var content = [];

		for (var i = 0; i < rows.length; i++)
		{
			if (rows[i].field !== undefined && rows[i].value !== undefined)
			{
				content.push(templates.fields_and_values.substitute(rows[i]));
			}
			else if (rows[i].br)
			{
				content.push(templates.br);
			}
			else if (rows[i].hr)
			{
				content.push(templates.hr);
			}
			else if (rows[i].title)
			{
				content.push(templates.title.substitute(rows[i]));
			}
			else if (rows[i].switch)
			{
				content.push(templates.switch.substitute(rows[i]));
			}
			else if (rows[i].collapse)
			{
				content.push(templates.collapse.substitute(rows[i]));
			}
			else if (rows[i].collapse_end)
			{
				content.push(templates.collapse_end);
			}
		}

		this.body.set('html', '<div class="form-horizontal">' + content.join('') + '</div>');
	},

	addListeners: function(new_listeners)
	{
		new_listeners.forEach(this.addListener.bind(this));

		return this;
	},

	addListener: function(new_listener)
	{
		this.listeners.push(new_listener);

		return this;
	},

	lock: function(locking)
	{
		jQuery(this.modal).modal({backdrop: locking ? 'static' : true, keyboard: !locking});
	},

	lockPersistent: function(locked){
		if (locked)
		{
			jQuery(this.modal).bind('hide.bs.modal', this.persistent_lock_handler);
		}
		else
		{
			jQuery(this.modal).unbind('hide.bs.modal', this.persistent_lock_handler);
		}
	},

	show: function()
	{
		jQuery(this.modal).modal('show');
	},

	hide: function()
	{
		jQuery(this.modal).modal('hide');
	},

	dispose: function()
	{
		this.hide();
	},

	destroy: function()
	{
		this.listeners.forEach(function(listener){
			listener.removeEvents();
		});
		this.modal.destroy();
	}
});
